// --- Home JS

import '../styles/layouts/home.scss';

import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

document.addEventListener("DOMContentLoaded", function () {
    const understandCarousel = document.querySelector('.understand__carousel');
    new Swiper(understandCarousel.querySelector('.swiper'), {
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: false,
        loop: true,
        navigation: {
            nextEl: understandCarousel.querySelector('.carousel__controller .swiper-button-next'),
            prevEl: understandCarousel.querySelector('.carousel__controller .swiper-button-prev'),
        },
        enabled: false,
        // Responsive
        breakpoints: {
            992: {
                enabled: true,
                slidesPerView: 2.2,
                spaceBetween: 20,
            },
            1400: {
                enabled: true,
                slidesPerView: 2.65,
                spaceBetween: 20,
            }
        }
    });
});